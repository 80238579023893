import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthInput, AuthPanelButton } from "./AuthElements";
import { ButtonSpinner } from "../UI/FormElements";
import { enterChangeFocus, requestToServer, formatServerErorrs } from "../utils/appUtils";

const ChangePassword = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let formData = new FormData(event.target);
    setLoading(true);
    setErrors({});
    requestToServer("/changepassword", { method: 'POST', body: formData },
      (response) => {
        localStorage.removeItem('token');
        return navigate("/login");
      }, (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  return (
    <div className="container-xxl">
      <div className="row justify-content-center align-items-center" style={{ minHeight: "var(--clientheight)" }}>
        <div className="col-12 col-sm-11 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
          <div className="card">
            <h6 className="card-header card-title">Изменение пароля</h6>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <fieldset disabled={loading}>
                  <AuthInput label="Старый пароль" type="password" name="oldpassword" onKeyDown={enterChangeFocus} errors={errors} required autoFocus />
                  <AuthInput label="Новый пароль" type="password" name="password" onKeyDown={enterChangeFocus} errors={errors} required />
                  <AuthInput label="Повторите пароль" type="password" name="password_confirmation" onKeyDown={enterChangeFocus} errors={errors} required />
                  <AuthPanelButton>
                    <ButtonSpinner loading={loading}>Изменить пароль</ButtonSpinner>
                  </AuthPanelButton>
                </fieldset>
              </form>
            </div>
            {errors._message_ && (<div className="card-footer text-center form-error py-3">{errors._message_}</div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
