import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import moment from "moment/moment";
import $ from "jquery";
import DataTable from 'datatables.net'
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { requestToServer, formatServerErorrs } from "../utils/appUtils";
import { Input, TextArea, ButtonSpinner } from '../UI/FormElements'
import TableDT from "../UI/TableDT";
import LoginHistory from "./LoginHistory";

let ROUTE_URL = "/sys_users";
const initialData = { user_id: "", active: 1, Flogin: "", password: "", login: "", email: "", full_name: "", comment: "" };

let dtRoles = {
  DOM: null,
  getTableOptions: (data) => {
    const dtOptions =
    {
      ordering: true, lengthChange: false, filter: false, paging: false, info: false, processing: false,
      order: [[1, "asc"]],
      data: data,
      columns: [
        {
          data: "sys_users_count", title: "Входит в роль", className: "dt-center", width: "15%",
          render: function (data, type, row, meta) {
            return type === 'display' ? `<input type="checkbox" name="belongs[]" class="form-check-input" value="${row.id}" ${data == 1 ? "checked" : ""} />` : data;
          }
        },
        { data: "name", title: "Наименование", render: DataTable.render.text() },
        { data: "description", title: "Описание", render: DataTable.render.text() },
      ],
    };
    return dtOptions;
  }
};

const SysUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialData);
  const [serverData, setServerData] = useState(null);
  const { showErrorPage } = useOutletContext();
  const navigate = useNavigate();

  let urlParams = useParams();
  let params = { isNew: urlParams.id === "create", id: urlParams.id }

  const afterLoadData = (response) => {
    if (params.isNew) {
      setData(initialData);
      setServerData(response);
    }
    else {
      let newData = {};
      for (let key in initialData) {
        newData[key] = (key in response.sys_user ? response.sys_user[key] : initialData[key]);
      }
      setData(newData);
      setServerData(response);
    }
  }

  const handleClose = (event) => {
    if (loading) return;
    navigate(-1);
  }
  const handleChange = (event) => {
    if (loading) return;
    setData({ ...data, [event.target.name]: event.target.value });
  }
  const handleTabSelect = (event) => {
    DataTable.tables({ visible: true, api: true }).columns.adjust();
  }

  const handleToggleActive = (event) => {
    if (loading) return;
    setLoading(true);
    let toggleActive = data.active == 1 ? 0 : 1;
    let formData = new FormData();
    formData.append("user_active", toggleActive);
    requestToServer(ROUTE_URL + "/" + params.id, { method: 'PATCH', body: formData },
      (response) => {
        setLoading(false);
        setData({ ...data, active: toggleActive });
      }, (error) => { setLoading(false); alert(JSON.stringify(error)); }, false);
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    let formData = new FormData();
    for (let key in data)
      formData.append(key, data[key]);
    $(dtRoles.DOM).find('tbody input[name="belongs[]"]:checked').each((index, element) => {
      formData.append("belongs[]", $(element).val());
    });
    requestToServer(ROUTE_URL + (params.isNew ? "" : "/" + params.id), { method: params.isNew ? 'POST' : 'PATCH', body: formData },
      (response) => { navigate(-1); }, (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtRoles.DOM = DOM;
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + (params.isNew ? "/create" : "/" + params.id), { method: 'GET' }, afterLoadData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  let caption = "Просмотр", isCanSave = false;
  if (params.isNew) {
    caption = "Добавление";
    isCanSave = true;
  }
  else if ((serverData.crud & 2) != 0) {
    caption = "Изменение";
    isCanSave = true;
  }

  let elementProps = { data, errors, onChange: handleChange };
  let playerInfo = (
    <div className="container-xxl">
      <form onSubmit={handleSubmitForm} autoComplete="off">
        <fieldset disabled={loading}>
          <div className="row form-gutter">
            <div className="col-md-1">
              <Input label="ID" name="user_id" {...elementProps} disabled />
            </div>
            <div className="col-md-4">
              <Input label="Логин" name="login" {...elementProps} required readOnly={!params.isNew} />
            </div>
            <div className="col-md-3">
              <Input label="Пароль" type="password" name="password" {...elementProps} />
            </div>
            <div className="col-md-4">
              <Input label="Email" name="email" {...elementProps} required />
            </div>

            <div className="col-md-12">
              <Input label="Полное имя" name="full_name" {...elementProps} required />
            </div>
            <div className="col">
              <TextArea label="Описание" name="comment" {...elementProps} />
            </div>
          </div>
          <div className="py-3">
            <h6 className="text-light-new text-center">Доступные роли</h6>
            <TableDT getOptions={() => dtRoles.getTableOptions(serverData.roles)} afterCreate={handleAfterDTCreate} />
          </div>
          {errors._message_ && <div className="form-error mb-2">{errors._message_}</div>}
          <div>
            {isCanSave && <ButtonSpinner className="btn-sm me-2" loading={loading}>Сохранить</ButtonSpinner>}
            <button type="button" className="btn btn-secondary btn-sm btn-our" onClick={handleClose}>Закрыть</button>
          </div>
        </fieldset>
      </form>
    </div>
  );

  if (params.isNew) {
    return (
      <MainArea>
        <MainAreaHeader caption={caption} />
        <MainAreaBody>
          {playerInfo}
        </MainAreaBody>
      </MainArea>
    );
  }
  else {
    let cardHeader = (
      <MainAreaHeader className="card-header py-1 px-2">
        <div className="d-flex align-items-stretch" >
          <div className="d-flex align-items-center flex-grow-1">
            <img className="pe-2" style={{ maxHeight: "65px" }} src={process.env.PUBLIC_URL + "/images/avatar.jpeg"} alt="avatar" />
            <div>
              <div className="fw-bold" id="edUserName" style={{ fontSize: "0.9rem" }}>{data.full_name}({data.login})</div>
              <div>
                <small className="text-light-new">Создан: </small><small>
                  {moment(serverData.sys_user.created_at).isValid() ? moment.utc(serverData.sys_user.created_at).local().format("DD.MM.YYYY HH:mm:ss") : serverData.sys_user.created_at}
                </small><br />
                <small className="text-light-new">Последний вход: </small><small>
                  {moment(serverData.last_login).isValid() ? moment.utc(serverData.last_login).local().format("DD.MM.YYYY HH:mm:ss") : serverData.last_login}
                </small>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="form-check form-switch">
              <input type="checkbox" className="form-check-input form-check-input-danger" name="user_active_checkbox"
                disabled={!isCanSave || loading} checked={!data.active} onChange={handleToggleActive} />
              <label className="form-check-label" style={{ verticalAlign: "middle" }} htmlFor="user_active_checkbox">Заблокирован</label>
            </div>
          </div>
          <div>
            <button className="btn btn-close ms-2" onClick={handleClose}></button>
          </div>
        </div>
      </MainAreaHeader >
    );

    return (
      <MainArea>
        {cardHeader}
        <MainAreaBody>
          <Tab.Container defaultActiveKey="playerInfo">
            <Nav as="ul" className="mb-2 our-nav no-gutters nav nav-fill">
              <Nav.Item as="li">
                <Nav.Link eventKey="playerInfo">Информация</Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="loginHistory">История входов</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="playerInfo">
                {playerInfo}
              </Tab.Pane>
              <Tab.Pane eventKey="loginHistory" onEntering={handleTabSelect}>
                <LoginHistory url={ROUTE_URL + "/" + params.id + "?query=login_history"} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </MainAreaBody>
      </MainArea>
    );
  }
}


export default SysUser;
