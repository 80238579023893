const CardHeaderDashboard = ({ loading, onClick, children }) => {

  return (
    <div className="card-header py-2">
      <div className="align-items-center justify-content-between d-flex">
        <div><h4 className="card-title mb-0">{children}</h4></div>
        {
          loading && (<div className="spinner-border spinner-border-sm text-primary"></div>)
        }
        <button className="btn btn-primary btm-sm rounded-pill py-1 px-2" title="Обновить" onClick={onClick} disabled={loading}>
          <i className="bi-repeat"></i>
        </button>
      </div>
    </div>
  );
}
export default CardHeaderDashboard;