import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import DataTable from 'datatables.net'
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer, formatServerErorrs } from '../utils/appUtils'
import TableDT from "../UI/TableDT";
import { ButtonSpinner } from '../UI/FormElements'

let ROUTE_URL = "/sys_users";

const Alerts = () => {
  const [loading, setLoading] = useState(false);
  const [serverData, setServerData] = useState(null);
  const [errors, setErrors] = useState({});
  const { showErrorPage } = useOutletContext();

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    dtTable.Api.search("").draw();
    let formData = new FormData(event.target);
    requestToServer(ROUTE_URL + "/save_send_alerts", { method: 'POST', body: formData }, () => { setLoading(false); },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  return (
    <form onSubmit={handleSubmitForm}>
      <MainArea>
        <MainAreaHeader caption="Настройка оповещений" />
        <MainAreaBody containerClass="container-xl">
          {errors._message_ && <div className="form-error mb-2">{errors._message_}</div>}
          <div>
            {
              (serverData.crud & 2) !== 0 && <ButtonSpinner className="btn-sm me-2" loading={loading} disabled={loading}>Сохранить</ButtonSpinner>
            }
          </div>
          <TableDT getOptions={() => dtTable.getTableOptions(serverData.data)} afterCreate={handleAfterDTCreate} />
        </MainAreaBody>
      </MainArea>
    </form>
  );
}

let dtTable = {
  Api: null,
  getTableOptions: (data) => {
    let dtOptions;
    dtOptions =
    {
      ordering: true, lengthChange: false, filter: true, paging: false, info: true, processing: false,
      order: [[1, "asc"]],
      ajax: { type: "GET", url: ROUTE_URL },      
      dom: getDataTableDom("iflp", 1, 1),
      columns: [
        {
          data: "send_alerts", title: "Оповещать по email", className: "dt-center", width: "20%",
          render: function (data, type, row, meta) {
            return type === 'display' ? `<input type="checkbox" name="belongs[]" class="form-check-input" value="${row.user_id}" ${data == 1 ? "checked" : ""} />` : data;
          }
        },
        { data: "full_name", title: "ФИО", render: DataTable.render.text() },        
        { data: "email", title: "Email", render: DataTable.render.text() },
      ],
    };
    return dtOptions;
  }
}

export default Alerts;
