// import { Routes, Route } from "react-router-dom";
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

import "./components/utils/DataTablesUtils";
import Layout from "./components/layout/Layout.jsx";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ErrorPage from "./components/ErrorPage.js";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";
import Rules from "./components/access/Rules.jsx";
import Roles from "./components/access/Roles.jsx";
import SettingAccess from "./components/access/SettingAccess.jsx";
import SysUsers from "./components/access/SysUsers.jsx";
import SysUser from "./components/access/SysUser.jsx";
import AccessObjects from "./components/others/AccessObjects";
import AccessSubjects from "./components/others/AccessSubjects";
import Availability from "./components/others/Availability";
import Events from "./components/others/Events";
import VirtualEnv from "./components/others/VirtualEnv";
import Configuration from "./components/others/Configuration";
import AccessSubjectsSettings from "./components/setting/AccessSubjectsSettings";
import AccessObjectsSettings from "./components/setting/AccessObjectsSettings";
import Alerts from "./components/setting/Alerts";

const App = createBrowserRouter(
   createRoutesFromElements(
      <>
         <Route path="/login" element={<Login />} />
         <Route path="/forgot_password" element={<ForgotPassword />} />
         <Route path="/reset_password/:token/:login" element={<ResetPassword />} />
         <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
            <Route index element={<Home />} />
            <Route path="changepassword" element={<ChangePassword />} errorElement={<ErrorPage />} />
            <Route path="rules" element={<Rules />} errorElement={<ErrorPage />} />
            <Route path="rules/:id/roles" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles" element={<Roles />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/rules" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="roles/:id/sys_users" element={<SettingAccess />} errorElement={<ErrorPage />} />
            <Route path="sys_users" element={<SysUsers />} errorElement={<ErrorPage />} />
            <Route path="sys_users/:id" element={<SysUser />} errorElement={<ErrorPage />} />
            <Route path="access_objects" element={<AccessObjects />} errorElement={<ErrorPage />} />
            <Route path="access_subjects" element={<AccessSubjects />} errorElement={<ErrorPage />} />
            <Route path="availability" element={<Availability />} errorElement={<ErrorPage />} />
            <Route path="events" element={<Events />} errorElement={<ErrorPage />} />
            <Route path="virtual_env" element={<VirtualEnv />} errorElement={<ErrorPage />} />
            <Route path="configuration" element={<Configuration />} errorElement={<ErrorPage />} />
            <Route path="access_subjects_settings" element={<AccessSubjectsSettings />} errorElement={<ErrorPage />} />
            <Route path="access_objects_settings" element={<AccessObjectsSettings />} errorElement={<ErrorPage />} />
            <Route path="alerts" element={<Alerts />} errorElement={<ErrorPage />} />
            <Route path="*" element={<ErrorPage errorCode="404" />} />
         </Route>
      </>
   )
);

export default App;
