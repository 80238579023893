import { useRouteError } from 'react-router-dom';
import { MainArea, MainAreaBody } from "./layout/MainArea";
import styles from './ErrorPage.module.css'

const ErrorPage = ({ errorCode = 404, errorName = null, errorDescription = "", errorObject = null, fullScreen }) => {
  let errorUrl;
  let error = useRouteError();
  if (!error) error = errorObject;
  if (error) {
    if (error instanceof TypeError) {
      errorCode = null;
      errorName = error.name + ": " + error.message;
      errorDescription = error.stack;
    }
    else if (error instanceof Error) {
      errorCode = null;
      errorName = error.name;
      errorDescription = error.message;
    }
    else {
      errorCode = error.status;
      errorName = error.statusText;
      errorDescription = error.data;
      errorUrl = error.url;
    }
  }

  let imageTag;
  if (errorCode == 404) {
    if (!errorName) errorName = "Страница не найдена";
    imageTag = (
      <i className={`${styles.info} ${styles.iconLargeBI} bi-question-circle-fill`}></i>
      // <svg className={`${styles.info} ${styles.iconLarge}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      //   <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
      // </svg>
    );
  }
  else if (errorCode == 403) {
    if (!errorName) errorName = "Доступ запрещен";
    imageTag = (
      <i className={`${styles.error} ${styles.iconLargeBI} bi-x-circle-fill`}></i>
      // <svg className={`${styles.error} ${styles.iconLarge}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      //   <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
      // </svg>
    );
  }
  else {
    if (!errorName)
      errorName = errorCode >= 500 ? "Внутренняя ошибка сервера" : "Ошибка";
    imageTag = (
      <i className={`${styles.error} ${styles.iconLargeBI} bi-exclamation-triangle-fill`}></i>
      // <svg className={`${styles.error} ${styles.iconLarge}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
      //   <path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path>
      // </svg>
    );
  }

  let errorJSX = (
    <>
      <div className={`${styles.animated} ${styles.fadeIn}`}>{imageTag}</div>
      <h1 className={`${styles.errorText} ${styles.animated} ${styles.fadeIn} ${styles.delay}`}>{errorName}</h1>
      <div className={`${styles.descriptionText} ${styles.animated} ${styles.fadeIn} ${styles.delay}`}>
        <section className={styles.footer}><strong>Код ответа:</strong> {errorCode}</section>
        {errorUrl && <p>{errorUrl}</p>}
        <p style={{ textAlign: "left" }}>
          {
            errorDescription?.split('\n').map(function (item, idx) {
              return (<span key={idx}>{item}<br /></span>)
            })
          }
        </p>
      </div>
    </>
  );

  if (fullScreen)
    return <div className={`w-100 px-2 text-wrap ${styles.errorBody}`}>{errorJSX}</div>
  else
    return (
      <MainArea>
        <MainAreaBody className={styles.errorBody}>
          {errorJSX}
        </MainAreaBody>
      </MainArea>
    );
}

export default ErrorPage
