import { MainArea } from "../layout/MainArea"
import CardAbonents from '../home/cardAbonents';

function Configuration() {

  return (
    <>
      <MainArea>
        <CardAbonents dashboard={false} />
      </MainArea>
    </>
  );
}

export default Configuration;
