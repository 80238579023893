import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea"
import LoginHistory from "../access/LoginHistory";

function Events() {

  return (
    <>
      <MainArea>
        <MainAreaHeader caption="Регистрация событий безопасности" />
        <MainAreaBody>
          <LoginHistory type="login_history" url="/login_history" />
        </MainAreaBody>
      </MainArea>
    </>
  );
}

export default Events;
