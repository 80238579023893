import { useEffect, useState } from "react";
import { requestToServer } from "../utils/appUtils";
import CardHeaderDashboard from "../home/cardHeaderDashboard";
import { MainArea, MainAreaBody } from "../layout/MainArea"

const VirtualEnv = (props) => {
  const [loading, setLoading] = useState(false);
  const [monitorData, setMonitorData] = useState("");

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    refreshData();
  }

  const refreshData = () => {
    let url = new URL(process.env.REACT_APP_API_URL + "/dashboard/monitor");
    url.searchParams.set('type', 'lsblk');
    setLoading(true);
    requestToServer(url.href, { method: 'GET' }, (response) => {
      setMonitorData(response.data);
      setLoading(false);
    }, (error) => { setLoading(false); console.error('dashboard', error) }, false);
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <MainArea>
      <CardHeaderDashboard loading={loading} onClick={handleClick}>Виртуальная среда</CardHeaderDashboard>
      <MainAreaBody containerClass="">
        <pre className="my-0" style={{ fontSize: "1em"}}>{monitorData}</pre>
      </MainAreaBody>
    </MainArea>
  );
}

export default VirtualEnv;

