import { useState } from "react";

const SelectPeriod = (props) => {
  const [period, setPeriod] = useState({ ...props.params });

  const handlePeriodChange = (event) => {
    setPeriod({ ...period, [event.target.name]: event.target.value });
  }
  const handleRefreshClick = (event) => {
    props.refreshClick(event, period);
  }

  return (
    <div className="d-flex flex-row align-items-center flex-wrap justify-content-center">
      <fieldset disabled={props.loading}>
        <div className="d-flex my-1 align-items-center">
          <label>С</label>
          <input className="mx-1 form-control form-control-sm " type="date" name="dat_start" value={period.dat_start} onChange={handlePeriodChange} />
          <label>по</label>
          <input className="mx-1 form-control form-control-sm" type="date" name="dat_end" value={period.dat_end} onChange={handlePeriodChange} />
        </div>
      </fieldset>
      <fieldset disabled={props.loading}>
        <div className="d-flex my-1">
          <button className="me-1 btn btn-sm btn-primary btn-our" name="btnRefresh" type="button" onClick={handleRefreshClick}>Обновить</button>
          {props.children}
        </div>
      </fieldset>
    </div>
  );
}

export default SelectPeriod