import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoAPP } from '../../images/logo.svg';

const AuthWindow = ({ children, errors }) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("login");
    if (localStorage.getItem('token'))
      navigate('/');
    let themeColor = localStorage.getItem('themeColor');
    if (themeColor === null)
      themeColor = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    document.documentElement.setAttribute('data-bs-theme', themeColor === "dark" ? "dark" : "light")
  }, [])

  return (
    <div className="container-xxl">
      <div className="row justify-content-center align-items-center" style={{ height: "85vh" }}>
        <div className="col-12 col-sm-11 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
          <div className="card login" style={{ fontSize: "13px" }}>
            <div className="card-header text-center d-flex align-items-center justify-content-center py-1">
              <LogoAPP style={{ maxHeight: "40px" }} />
            </div>
            <div className="card-body">
              {children}
            </div>
            {errors._message_ && (<div className="card-footer text-center form-error py-3">{errors._message_}</div>)}
          </div>
        </div>
      </div>
    </div>
  );

}

export default AuthWindow;