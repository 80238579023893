import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import DataTable from 'datatables.net'
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer, formatServerErorrs } from '../utils/appUtils'
import TableDT from "../UI/TableDT";
import { ButtonSpinner } from '../UI/FormElements'

let ROUTE_URL = "";

const SettingCRUD = () => {
  const [loading, setLoading] = useState(false);
  const [serverData, setServerData] = useState(null);
  const [errors, setErrors] = useState({});
  const { showErrorPage } = useOutletContext();
  const navigate = useNavigate();

  let currentLocation = useLocation();
  ROUTE_URL = currentLocation.pathname;

  const handleSubmitForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrors({});
    setLoading(true);
    dtTable.Api.search("").draw();
    let formData = new FormData(event.target);
    requestToServer(ROUTE_URL, { method: 'POST', body: formData }, () => { navigate(-1) },
      (error) => { setLoading(false); setErrors(formatServerErorrs(event.target, error)); }, false);
  }

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  return (
    <form onSubmit={handleSubmitForm}>
      <MainArea>
        <MainAreaHeader caption={`Настройка ${serverData.parent.name} - ${serverData.parent.description}`} />
        <MainAreaBody containerClass="container-xl">
          {errors._message_ && <div className="form-error mb-2">{errors._message_}</div>}
          <div>
            {
              (serverData.crud & 2) !== 0 && <ButtonSpinner className="btn-sm me-2" loading={loading} disabled={loading}>Сохранить</ButtonSpinner>
            }
            <button type="button" className="btn btn-secondary btn-sm btn-our" disabled={loading} onClick={() => { navigate(-1); }}>Закрыть</button>
          </div>
          <TableDT getOptions={() => dtTable.getTableOptions(serverData.data, currentLocation.pathname)} afterCreate={handleAfterDTCreate} />
        </MainAreaBody>
      </MainArea>
    </form>
  );
}

let dtTable = {
  Api: null,
  getTableOptions: (data, location) => {
    let dtOptions;
    if (location.toLowerCase().indexOf("sys_users") >= 0) {
      dtOptions =
      {
        ordering: true, lengthChange: false, filter: true, paging: false, info: true, processing: false,
        order: [[1, "desc"], [2, "asc"]],
        dom: getDataTableDom("iflp", 1, 1),
        data: data,
        columns: [
          {
            data: "crud", title: "Входит в роль", className: "dt-center", width: "15%",
            render: function (data, type, row, meta) {
              return type === 'display' ? `<input type="checkbox" name="belongs[]" class="form-check-input" value="${row.user_id}" ${data == 1 ? "checked" : ""} />` : data;
            }
          },
          {
            data: "active", title: "Заблокирован", className: "dt-center", width: "15%",
            render: function (data, type, row, meta) {
              if (type === "display") return data == 1 ? "&nbsp" : "&#10003;";
              else return data;
            },
          },
          { data: "login", title: "Логин", render: DataTable.render.text() },
          { data: "full_name", title: "ФИО", render: DataTable.render.text() },
        ],
      };
    }
    else {
      dtOptions =
      {
        ordering: true, lengthChange: false, filter: true, paging: false, info: true, processing: false,
        order: [[4, "asc"]],
        dom: getDataTableDom("iflp", 1, 1),
        data: data,
        columns: [
          {
            data: null, title: "Создание", className: "dt-body-center",
            render: function (data, type, row, meta) {
              let crudPart = (row.crud & 8) === 8 ? 1 : 0;
              return type === 'display' ? `<input type="checkbox" name="create[]" class="form-check-input" value="${row.id}" ${crudPart === 1 ? "checked" : ""} />` : crudPart;
            }
          },
          {
            data: null, title: "Просмотр", className: "dt-body-center",
            render: function (data, type, row, meta) {
              let crudPart = (row.crud & 4) === 4 ? 1 : 0;
              return type === 'display' ? `<input type="checkbox" name="read[]" class="form-check-input" value="${row.id}" ${crudPart === 1 ? "checked" : ""} />` : crudPart;
            }
          },
          {
            data: null, title: "Изменение", className: "dt-body-center",
            render: function (data, type, row, meta) {
              let crudPart = (row.crud & 2) === 2 ? 1 : 0;
              return type === 'display' ? `<input type="checkbox" name="update[]" class="form-check-input" value="${row.id}" ${crudPart === 1 ? "checked" : ""} />` : crudPart;
            }
          },
          {
            data: null, title: "Удаление", className: "dt-body-center",
            render: function (data, type, row, meta) {
              let crudPart = (row.crud & 1) === 1 ? 1 : 0;
              return type === 'display' ? `<input type="checkbox" name="delete[]" class="form-check-input" value="${row.id}" ${crudPart === 1 ? "checked" : ""} />` : crudPart;
            }
          },
          { data: "name", title: "Наименование", render: DataTable.render.text() },
          { data: "description", title: "Описание", render: DataTable.render.text() },
        ],
      };
    }
    return dtOptions;
  }
}

export default SettingCRUD;