import moment from "moment/moment";
import { useState } from "react";
import CardHeaderDashboard from "./cardHeaderDashboard";

let services = [
  { id: 1, name: "ПК администрирования КОНСУЛ", status: 0, date: (new Date((new Date()) - 25000 * 750 * 60)) },
  { id: 2, name: "ПК диагностики состояния КОНСУЛ", status: 0, date: (new Date((new Date()) - 23000 * 10150 * 60)).toISOString() },
  { id: 3, name: "ПК позиционирования, навигации и мониторинга", status: 1, date: (new Date((new Date()) - 33000 * 110150 * 60)) },
  { id: 4, name: "ПК аналитической обработки и визуального представления данных", status: 0, date: (new Date((new Date()) - 43000 * 20150 * 60)) },
  { id: 5, name: "ПК интеграционной платформы", status: 0, date: (new Date((new Date()) - 100000 * 15150 * 60)) },
  { id: 5, name: "ПК диспетчерского управления, в том числе базами данных АПК", status: 0, date: (new Date((new Date()) - 11000 * 15150 * 60)) },
  { id: 5, name: "ПК управления инцидентами", status: 0, date: (new Date((new Date()) - 50000 * 15150 * 60)) },
  { id: 5, name: "ПК коммерческой платформы", status: 1, date: (new Date((new Date()) - 70000 * 15150 * 60)) },
];

const CardSystems = (props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  let styleCard = {};
  let classCard = "card h-100";
  if (props.dashboard === false)
  {
    styleCard = { minHeight: "var(--clientheight)" };
    classCard = "card border-0";
  }

  return (
    <div className={classCard} style={styleCard}>
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
        Доступность программных комплексов
      </CardHeaderDashboard>
      <div className="card-body" style={{ fontSize: "0.85rem" }}>
        {(
          services.map((item, index, array) => {
            return (
              <div key={item.id} className={`col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                <i className={`fs-5 me-2 ${item.status == 0 ? "bi-check-circle-fill text_green" : "bi-x-circle-fill text-danger"}`}></i>
                <div>
                  <span className="link-info">
                    {item.name} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default CardSystems;