import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../layout/MainArea";
import { getDataTableDom, requestToServer } from '../utils/appUtils'
import TableDT from "../UI/TableDT";

const ROUTE_URL = "/sys_users";

const SysUsers = () => {
  const [serverData, setServerData] = useState(null);
  const { showErrorPage, messageBox } = useOutletContext();
  const navigate = useNavigate();

  const handleAddRow = () => {
    navigate("./create");
  }

  const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlDelete = ROUTE_URL + "/" + data.user_id;
      messageBox({
        show: true, message: `Вы действительно хотите удалить "${data.login}"?`, mbOk: (e) => {
          e.preventDefault();
          messageBox({ show: false, mbOk: null });
          requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
        }
      });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
      e.preventDefault();
      let data = dtTable.Api.row($(this).parents('tr')).data();
      let urlStr = "./" + data.user_id;
      navigate(urlStr);
    });
  }

  useEffect(() => {
    requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
  }, []);

  if (serverData === null)
    return <MainArea loading />;

  return (
    <MainArea>
      <MainAreaHeader caption="Сотрудники" />
      <MainAreaBody containerClass="">
        {
          (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
        }
        <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
      </MainAreaBody>
    </MainArea>
  );
};

let dtTable = {
  Api: null,
  getTableOptions: (crud) => {
    const dtOptions = {
      ordering: true, lengthChange: true, filter: true, paging: true, info: true, processing: true, deferRender: true,
      order: [[2, "desc"], [3, "asc"],],
      ajax: { type: "GET", url: ROUTE_URL },
      dom: getDataTableDom("lfip", 2, 1),
      columns: [
        { orderable: false, searchable: false, data: null, className: "td-btn-action dt-center", render: DataTable.render.btnDelete(), },
        { data: "user_id", title: "User Id", className: "dt-body-right dt-head-center", },
        {
          data: "active", title: "Active", className: "dt-center",
          render: function (data, type, row, meta) {
            if (type === "display") return data == 1 ? "&#10003;" : "&nbsp";
            else return data;
          },
        },
        { data: "login", title: "Логин", render: DataTable.render.dataToAnchor("btnShowEdit"), },
        { data: "full_name", title: "Наименование", },
        { data: "email", title: "Email", },
        { data: "comment", title: "Комментарий", render: DataTable.render.text(), },
        { data: "created_at", title: "Дата создания", render: DataTable.render.moment("DD.MM.YYYY HH:mm:ss", true), },
      ],
    };
    if ((crud & 1) === 0) {
      dtOptions.order = [[1, "desc"], [2, "asc"]];
      dtOptions.columns.splice(0, 1);
      dtOptions.dom = getDataTableDom("lfip", 1, 1);
    }
    return dtOptions;
  }
}

export default SysUsers;
