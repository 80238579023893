/*
 ---------------------------------------
 : Custom - Table Datatable js :
 ---------------------------------------
 */
import $ from "jquery";
import DataTable from 'datatables.net';
import moment from "moment/moment";

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
  if (!options.url.includes(":")) {
    options.url = process.env.REACT_APP_API_URL + options.url;
    jqXHR.setRequestHeader('Authorization', localStorage.getItem('token'));
  }
});

if (typeof DataTable !== "undefined") {

  DataTable.render.moment = function (toFormat, toLocale = true, anchorName = null) {
    return function (data, type, row) {
      if (!data)
        return type === 'sort' || type === 'type' ? 0 : data;
      let v_date, v_ret;
      if (toLocale)
        v_date = moment.utc(data).local();
      else
        v_date = moment(data);
      if (type === 'sort' || type === 'type')
        v_ret = v_date.valueOf();
      else if (toFormat === 'toLocaleDateString')
        v_ret = v_date.toDate().toLocaleDateString();
      else if (toFormat === 'toLocaleString')
        v_ret = v_date.toDate().toLocaleString();
      else
        v_ret = v_date.format(toFormat);
      return (type === 'display' && anchorName) ? `<a href="#" name="${anchorName}">${v_ret}</a>` : v_ret;
    };
  };

  DataTable.render.btnDelete = function () {
    return function (data, type, row) {
      return type === "display" ? '<button class="btn btn-sm btn-warning" name="btnDelete" title="Удалить"><i class="fa fa-trash" style="font-size:1.1em"></i></button>' : null;
    };
  };
  DataTable.render.btnDetail = function (btnName) {
    return function (data, type, row) {
      return type === "display" ? `<div class="td-div-flex"><div class="count me-1"><span>${data}</span></div><div><button name="${btnName}" class="btn btn-sm btn-primary"><i class="icon-options"></i></button></div></div>` : data;
    };
  };
  DataTable.render.dataToAnchor = function (anchorName, href = "#") {
    return function (data, type, row) {
      return type === "display" ? `<a href="${href}" name="${anchorName}">` + DataTable.render.text().display(data) + "</a>" : data;
    };
  };

  let dataTableLanguages = {
    'ru': {
      "processing": '<div class="d-flex align-items-center justify-content-center py-3"><i class="mr-1 fa fa-spinner fa-spin fa-2x fa-fw"></i><span>Подождите...</span></div>',
      "search": "Поиск:",
      "lengthMenu": "Показать _MENU_ записей",
      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
      "infoEmpty": "Записи с 0 до 0 из 0 записей",
      "infoFiltered": "(отфильтровано из _MAX_ записей)",
      "loadingRecords": '<span>Загрузка данных...</span>',
      "zeroRecords": "Записи отсутствуют.",
      "emptyTable": "Нет данных",
      "paginate": {
        "first": "Первая",
        "previous": "Предыдущая",
        "next": "Следующая",
        "last": "Последняя"
      },
      "aria": {
        "sortAscending": ": активировать для сортировки столбца по возрастанию",
        "sortDescending": ": активировать для сортировки столбца по убыванию"
      },
      "select": {
        "rows": {
          "_": "Выбрано записей: %d",
          "0": "Кликните по записи для выбора",
          "1": "Выбрана одна запись"
        },
        "1": "%d ряд выбран",
        "_": "%d ряда(-ов) выбрано",
        "cells": {
          "1": "1 ячейка выбрана",
          "_": "Выбрано %d ячеек"
        },
        "columns": {
          "1": "1 столбец выбран",
          "_": "%d столбцов выбрано"
        }
      },
      "searchBuilder": {
        "conditions": {
          "string": {
            "notEmpty": "Не пусто",
            "startsWith": "Начинается с",
            "contains": "Содержит",
            "empty": "Пусто",
            "endsWith": "Заканчивается на",
            "equals": "Равно",
            "not": "Не"
          },
          "date": {
            "after": "После",
            "before": "До",
            "between": "Между",
            "empty": "Пусто",
            "equals": "Равно",
            "not": "Не",
            "notBetween": "Не между",
            "notEmpty": "Не пусто"
          },
          "moment": {
            "after": "После",
            "before": "До",
            "between": "Между",
            "empty": "Не пусто",
            "equals": "Между",
            "not": "Не",
            "notBetween": "Не между",
            "notEmpty": "Не пусто"
          },
          "number": {
            "between": "В промежутке от",
            "empty": "Пусто",
            "equals": "Равно",
            "gt": "Больше чем",
            "gte": "Больше, чем равно",
            "lt": "Меньше чем",
            "lte": "Меньше, чем равно",
            "not": "Не",
            "notBetween": "Не в промежутке от",
            "notEmpty": "Не пусто"
          }
        },
        "data": "Данные",
        "deleteTitle": "Удалить условие фильтрации",
        "logicAnd": "И",
        "logicOr": "Или",
        "title": {
          "0": "Конструктор поиска",
          "_": "Конструктор поиска (%d)"
        },
        "value": "Значение",
        "add": "Добавить условие",
        "button": {
          "0": "Конструктор поиска",
          "_": "Конструктор поиска (%d)"
        },
        "clearAll": "Очистить всё",
        "condition": "Условие"
      },
      "searchPanes": {
        "clearMessage": "Очистить всё",
        "collapse": {
          "0": "Панели поиска",
          "_": "Панели поиска (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Нет панелей поиска",
        "loadMessage": "Загрузка панелей поиска",
        "title": "Фильтры активны - %d"
      },
      "thousands": ",",
      "buttons": {
        "pageLength": {
          "_": "Показать 10 строк",
          "-1": "Показать все ряды",
          "1": "Показать 1 ряд"
        },
        "pdf": "PDF",
        "print": "Печать",
        "collection": "Коллекция <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
        "colvis": "Видимость столбцов",
        "colvisRestore": "Восстановить видимость",
        "copy": "Копировать",
        "copyKeys": "Нажмите ctrl or u2318 + C, чтобы скопировать данные таблицы в буфер обмена.  Для отмены, щелкните по сообщению или нажмите escape.",
        "copySuccess": {
          "1": "Скопирована 1 ряд в буфер обмена",
          "_": "Скопировано %ds рядов в буфер обмена"
        },
        "copyTitle": "Скопировать в буфер обмена",
        "csv": "CSV",
        "excel": "Excel"
      },
      "decimal": ".",
      "infoThousands": ",",
      "autoFill": {
        "cancel": "Отменить",
        "fill": "Заполнить все ячейки <i>%d<i><\/i><\/i>",
        "fillHorizontal": "Заполнить ячейки по горизонтали",
        "fillVertical": "Заполнить ячейки по вертикали",
        "info": "Пример автозаполнения"
      }
    },
    'en': {
      "processing": '<div class="d-flex align-items-center justify-content-center"><i class="mr-1 fa fa-spinner fa-spin fa-2x fa-fw"></i><span>Processing...</span></div>',
      "emptyTable": "No data available in table",
      "info": "Showing _START_ to _END_ of _TOTAL_ entries",
      "infoEmpty": "Showing 0 to 0 of 0 entries",
      "infoFiltered": "(filtered from _MAX_ total entries)",
      "infoThousands": ",",
      "lengthMenu": "Show _MENU_ entries",
      "loadingRecords": '<span>Loading...</span>',
      "search": "Search:",
      "zeroRecords": "No matching records found",
      "thousands": ",",
      "paginate": {
        "first": "First",
        "last": "Last",
        "next": "Next",
        "previous": "Previous"
      },
      "aria": {
        "sortAscending": ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
      },
      "autoFill": {
        "cancel": "Cancel",
        "fill": "Fill all cells with <i>%d<\/i>",
        "fillHorizontal": "Fill cells horizontally",
        "fillVertical": "Fill cells vertically"
      },
      "buttons": {
        "collection": "Collection <span class='ui-button-icon-primary ui-icon ui-icon-triangle-1-s'\/>",
        "colvis": "Column Visibility",
        "colvisRestore": "Restore visibility",
        "copy": "Copy",
        "copyKeys": "Press ctrl or u2318 + C to copy the table data to your system clipboard.<br><br>To cancel, click this message or press escape.",
        "copySuccess": {
          "1": "Copied 1 row to clipboard",
          "_": "Copied %d rows to clipboard"
        },
        "copyTitle": "Copy to Clipboard",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
          "-1": "Show all rows",
          "1": "Show 1 row",
          "_": "Show %d rows"
        },
        "pdf": "PDF",
        "print": "Print"
      },
      "searchBuilder": {
        "add": "Add Condition",
        "button": {
          "0": "Search Builder",
          "_": "Search Builder (%d)"
        },
        "clearAll": "Clear All",
        "condition": "Condition",
        "conditions": {
          "date": {
            "after": "After",
            "before": "Before",
            "between": "Between",
            "empty": "Empty",
            "equals": "Equals",
            "not": "Not",
            "notBetween": "Not Between",
            "notEmpty": "Not Empty"
          },
          "moment": {
            "after": "After",
            "before": "Before",
            "between": "Between",
            "empty": "Empty",
            "equals": "Equals",
            "not": "Not",
            "notBetween": "Not Between",
            "notEmpty": "Not Empty"
          },
          "number": {
            "between": "Between",
            "empty": "Empty",
            "equals": "Equals",
            "gt": "Greater Than",
            "gte": "Greater Than Equal To",
            "lt": "Less Than",
            "lte": "Less Than Equal To",
            "not": "Not",
            "notBetween": "Not Between",
            "notEmpty": "Not Empty"
          },
          "string": {
            "contains": "Contains",
            "empty": "Empty",
            "endsWith": "Ends With",
            "equals": "Equals",
            "not": "Not",
            "notEmpty": "Not Empty",
            "startsWith": "Starts With"
          },
          "array": {
            "without": "Without",
            "notEmpty": "Not Empty",
            "not": "Not",
            "contains": "Contains",
            "empty": "Empty",
            "equals": "Equals"
          }
        },
        "data": "Data",
        "deleteTitle": "Delete filtering rule",
        "leftTitle": "Outdent Criteria",
        "logicAnd": "And",
        "logicOr": "Or",
        "rightTitle": "Indent Criteria",
        "title": {
          "0": "Search Builder",
          "_": "Search Builder (%d)"
        },
        "value": "Value"
      },
      "searchPanes": {
        "clearMessage": "Clear All",
        "collapse": {
          "0": "SearchPanes",
          "_": "SearchPanes (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "No SearchPanes",
        "loadMessage": "Loading SearchPanes",
        "title": "Filters Active - %d"
      },
      "select": {
        "1": "%d row selected",
        "_": "%d rows selected",
        "cells": {
          "1": "1 cell selected",
          "_": "%d cells selected"
        },
        "columns": {
          "1": "1 column selected",
          "_": "%d columns selected"
        }
      }
    }
  };

  let language = dataTableLanguages['ru']; //_locale

  let opts = {
    "stateSave": true,
    "stateDuration": -1,
    "scrollX": true,
    "lengthMenu": [[15, 25, 50, -1], [15, 25, 50, "All"]],
    "iDisplayLength": 15,
    "language": language
  };
  //DataTable.defaults = { ...DataTable.defaults, opts };
  $.extend(true, DataTable.defaults, opts);
}
