import { MainArea } from "../layout/MainArea"
import CardSystems from "../home/cardSystems";

function Availability() {

  return (
    <>
      <MainArea>
        <CardSystems dashboard={false} />
      </MainArea>
    </>
  );
}

export default Availability;
