import React from 'react';
import { NavLink } from 'react-router-dom'

const MenuItems = (props) => {
  if (props.items === null) return null;
  let v_ret = props.items.map(function (item, index) {
    if ("submenu" in item)
      return (
        <li key={item.key} className="dropdown"><a href="#"><i className={"me-2 " + item.icon}></i>{item.name}</a>
          <ul>
            <MenuItems items={item.submenu} />
          </ul>
        </li>
      );
    else
      return (
        <li key={item.key}><NavLink to={item.action}><i className={"me-2 " + item.icon}></i>{item.name}</NavLink></li>
      );
  });
  return v_ret;
}

const Menu = (props) => {
  return (
    <div className="sidebar pt-0" data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          <MenuItems items={props.menu} />
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Menu);
